body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    box-sizing: border-box;
  }
  .App {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    width: 500px;
    box-shadow: 2px 3px 3px #eee;
    padding: 30px;
  }
  .container p {
    text-align: center;
    font-size: 16px;
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #c4c4c4;
    padding: 12px;
    font-size: 16px;
    margin-bottom: 12px;
    border-radius: 4px;
  }
  .btn {
    width: 100%;
    height: 50px;
    padding: 12px;
    font-size: 16px;
    margin-bottom: 12px;
    border-radius: 4px;
    background-color: rgb(71, 43, 0);
    color: #fff;
    border: 0;
  }

.payment_registration_type{
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}
  .select-class{
    width: 100% !important;
    height: 50px;
    margin: 0 auto;
    margin-top:20px;
    border-radius: 3px;
    border:1px solid #c4c4c4;
    background-color: #ffffff;
  } 
  