/* CSS */
.button-19 {
  appearance: button;
  background-color: #1899d6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: din-round, sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #1cb0f6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}

.rotatingText {
  font-family: "Georgia", serif;
  font-style: italic;
  font-size: 18px;
  text-align: center;
}

.rotatingText-adjective {
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  left: 0;
  margin-bottom: 0;
  margin-top: 50px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
}

.text_body {
  font-family: Arial;
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



@keyframes spin {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

#shared-loader {
  box-sizing: border-box;
  font-family: sans-serif;
  width: 200px;
  height: 200px;
  margin: 0;
  /* perspective: 400px; */
  transform: scale(0.2);
  /* border: 3px solid red; */
}

.cube {
  width: 200px;
  height: 200px;
  z-index: 20000000;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
  transition: transform 2s;
  animation: spin 6s linear infinite;
  margin: 0;
}

.cube-face {
  position: absolute;
  z-index: 20000000;
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  line-height: 200px;
  font-size: 80px;
  font-weight: bold;
  color: white;
  text-align: center;
  background: blue;
}

.cube-face-front {
  transform: rotateY(0deg) translateZ(100px);
}
.cube-face-right {
  transform: rotateY(90deg) translateZ(100px);
}
.cube-face-back {
  transform: rotateY(180deg) translateZ(100px);
}
.cube-face-left {
  transform: rotateY(-90deg) translateZ(100px);
}
.cube-face-top {
  transform: rotateX(90deg) translateZ(100px);
}
.cube-face-bottom {
  transform: rotateX(-90deg) translateZ(100px);
}